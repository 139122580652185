import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

// components
import Root from './Root';

// lazy load all the views

// home
const Home = React.lazy(() => import('../pages/Home'));

// AboutUs
const aboutus = React.lazy(() => import('../pages/aboutus'));

// landings
const Rawaf = React.lazy(() => import('../pages/Landing/Rawaf/Rawaf'));
const RawafAmlak = React.lazy(() => import('../pages/Landing/RawafAmlak/RawafAmlak'));

// Policy
const Privacy = React.lazy(() => import('../pages/Policy/Privacy'));
const Terms = React.lazy(()=> import('../pages/Policy/Terms'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    return useRoutes([
        {
            // root route
            path: '/',
            element: <Root />,
        },
        {
            // public routes
            path: '/',
            children: [
                {
                    path: 'home',
                    element: <LoadComponent component={Home} />,
                },
                {
                    path: 'landing',
                    children: [
                        { path: 'Rawaf', element: <LoadComponent component={Rawaf} /> },
                        { path: 'RawafAmlak', element: <LoadComponent component={RawafAmlak} /> }
                    ],
                },
                {
                    path: 'Policy',
                    children: [
                        { path: 'Privacy', element: <LoadComponent component={Privacy} /> },
                        { path: 'Terms', element: <LoadComponent component={Terms} /> }
                    ],
                },
                {
                    path: 'aboutus',
                    element: <LoadComponent component={aboutus} />,
                }
            ],
        }
    ]);
};

export default AllRoutes;
